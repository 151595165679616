import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { getContent } from "../apollo/preview"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import queryString from "query-string"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Content from "../components/Shared/Content"
import LoadMore from "../components/Shared/LoadMore"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import SearchResultsHeader from "../components/Search/SearchResultsHeader"
import SearchResultLeaderList from "../components/Search/SearchResultLeaderList"
import SearchResultLeader from "../components/Search/SearchResultLeader"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"

import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const PER_PAGE = 10

const TemplateSearch = (props) => {
    const search = queryString.parse(props.location.search)

    const page = getContent(props, "page")
    const venue = venueForPage({ page: page, menus: props.data.menus })

    return (
        <Layout venue={venue} currentLocation={props.path} title={`Search results`}>
            <SEOSettings entity={page}>
                <meta content="foo" name="test" />
            </SEOSettings>
            <HeroAreaSmall title="Search" imageID="OPyoU4zCwWI" />
            <SearchResults search={search} page={page} venue={venue} path={props.path} />
        </Layout>
    )
}

const SearchResults = ({ search }) => {
    const [params, setParams] = useState({ first: PER_PAGE, after: null, query: search.q || "" })
    const { loading, error, data, fetchMore } = useQuery(searchQuery, { variables: params })

    if (error) {
        return (
            <Main>
                <h1>No results.</h1>
            </Main>
        )
    }

    const fetchNextPage = () => {
        fetchMore({
            variables: { first: params.first, after: data.results.pageInfo.endCursor },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                let nodes = [...prev.results.nodes, ...fetchMoreResult.results.nodes]

                return Object.assign({}, prev, {
                    results: {
                        nodes: nodes,
                        pageInfo: fetchMoreResult.results.pageInfo,
                    },
                })
            },
        })
    }

    return (
        <Main>
            {data && <p>{data.results.nodes.count}</p>}

            <ContentWithSidebar>
                <Content>
                    <SearchResultsHeader query={params.query} count={data ? data.results.nodes.length : false} />

                    {loading && <span>...</span>}

                    {data && data.results && (
                        <>
                            <SearchResultLeaderList>
                                {data.results.nodes.map((result) => (
                                    <SearchResultLeader key={result.id} {...result} />
                                ))}
                            </SearchResultLeaderList>
                        </>
                    )}

                    {data && data.results.pageInfo.hasNextPage && <LoadMore onClick={() => fetchNextPage()} />}
                </Content>
            </ContentWithSidebar>
        </Main>
    )
}

export const pageQuery = graphql`
    query SearchPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }
        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
        }
    }
`

const searchQuery = gql`
query Search($query: String!, $first: Int!, $after: String) {
    results: contentNodes(
      first: $first
      after: $after
      where: {search: $query, contentTypes: [PAGE, EVENT, NEWS, VENUE, COLLECTION]}
    ) {
      nodes {
        type: __typename
        ... on Page {
          title
          link
          page_introduction {
            introduction
          }
        }
        ... on Venue {
          title
          link
          excerpt(format: RENDERED)
        }
        ... on Event {
          title
          link
          excerpt
        }
        ... on News {
          title
          link
          excerpt
        }
        ... on Collection {
          title
          link
          excerpt(format: RENDERED)
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
      }
    }
  }
`

export default TemplateSearch
