import React from "react"

import * as styles from "./SearchResultLeader.module.scss"

const SearchResultLeader = (props) => {
    
    
    
    
    return (
        <div className={styles.SearchResultLeader}>
            {/* {console.log(props.link)} */}
     <a href={props.link.includes("https://") ? props.link.replace("https://cms.derbymuseums.org/","/") : props.link}>
            
            {props.title && <h2>{props.title}</h2>}
            
            {props.excerpt && (
              <div dangerouslySetInnerHTML={{ __html: props.excerpt }} />
            )}
            
            {props.introduction && (
              <div dangerouslySetInnerHTML={{ __html: props.introduction }} />
            )}
          </a>
        </div>
      )};

export default SearchResultLeader
