import React from "react"

import * as styles from "./SearchResultsHeader.module.scss"

const SearchResultsHeader = ({query, count}) => {
    const getCount = () => count === 1 ? `${count} result` : `${count} results`

    return <div className={styles.SearchResultsHeader}>
        { query && query.length > 0 && 
            <h1>You searched for <mark>{query}</mark></h1>
        }
        
        {count !== false && 
            <span className={styles.resultCount}>{getCount()}</span>
        }
    </div>
}

export default SearchResultsHeader